export const APP_ASSET_BASEURL = 'https://asset.compareclub.com.au/'
export const CC_DOMAIN = 'https://compareclub.com.au'

// Default image placeholder for content
export const FEATURED_IMAGE_DEFAULT_IMAGE = 'https://asset.compareclub.com.au/content/default-featured-image.png'
export const expertEaseImg = 'https://asset.compareclub.com.au/edge/static/images/expert-ease.svg'

// Default image for URL preview
export const OG_DEFAULT_IMAGE = 'https://asset.compareclub.com.au/edge/static/images/thumbnail-cc.jpg'
export const OG_CLUB_PLUS = 'https://asset.compareclub.com.au/edge/static/images/thumbnail-clubplus.jpg'

export const CURRENT_YEAR = new Date().getFullYear()
